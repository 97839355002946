<div class="thumbnail openNearestSelector">
  <img *ngIf="renderService.getThumbnail(renderService.getElementLanguage(fileobject.ElementsFileobjects))"
       src="{{renderService.getThumbnail(renderService.getElementLanguage(fileobject.ElementsFileobjects))}}"
       [alt]="renderService.getAlt(renderService.getElementLanguage(fileobject.ElementsFileobjects))"/>
</div>

<div class="information">

  <h2 class="text-left s-small w-bold">
    <span
      [innerHTML]="renderService.getElementLanguage(fileobject.ElementsFileobjects).Element.title | safeHtml"></span>

    <span class="label" *ngIf="renderService.showObjectTypeTitle">
      <app-locallang [key]="'type.' + fileobject.Objecttypes.title"></app-locallang>
    </span>
  </h2>

  <small *ngIf="renderService.getElementLanguage(fileobject.ElementsFileobjects).Element.description"
         [innerHTML]="renderService.getElementLanguage(fileobject.ElementsFileobjects).Element.description | safeHtml">
  </small>

  <div class="selector">
    <ul>
      <li class="label">
        <a #filesDropdownOpener (click)="updateStackingContext()">
          <app-locallang key="download.link"></app-locallang>
        </a>
      </li>

      <ng-container *ngFor="let languageItem of languageService.languageSorting">
        <ng-container
          *ngFor="let elementsFileobject of renderService.getElementLanguageItems(fileobject.ElementsFileobjects, languageItem, ['import', 'upload'])">
          <li *ngIf="elementsFileobject.id">
            <a [href]="elementsFileobject.Element.url_download"
               (mousedown)="renderService.trackPageview(elementsFileobject)" target="_blank">

              [{{elementsFileobject.Element.extension}}]
              <app-locallang [key]="'language.' + elementsFileobject.Language.name"></app-locallang>

              <ng-container *ngIf="elementsFileobject.Element.filesize">
                ({{elementsFileobject.Element.filesize | filesize}})
              </ng-container>

              <!-- {{ renderService.getChangelogSuffix(elementsFileobject) }} -->

            </a>
          </li>
        </ng-container>
      </ng-container>

    </ul>
  </div>

  <div class="selector" *ngIf="renderService.hasElementType(fileobject.ElementsFileobjects, 'link')">
    <ul>
      <li class="label">
        <a href="#">
          <app-locallang key="downloads_dropdown_online"></app-locallang>
        </a>
      </li>

      <ng-container *ngFor="let languageItem of languageService.languageSorting">
        <ng-container
          *ngFor="let elementsFileobject of renderService.getElementLanguageItems(fileobject.ElementsFileobjects, languageItem, ['link'])">
          <li *ngIf="elementsFileobject.id">
            <a [href]="elementsFileobject.Element.path" target="_blank">
              <app-locallang [key]="'language.' + elementsFileobject.Language.name"></app-locallang>
            </a>
          </li>
        </ng-container>
      </ng-container>

    </ul>
  </div>

</div>
