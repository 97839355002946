<ng-container *ngIf="searchprofileConfig && locallangReady">
  <!-- SearchProfile -->
  <div class="vsfiles">

    <form *ngIf="!isHiddenFilters()" autocomplete="off">
      <ol *ngIf="searchprofileData" class="vsfiles-filters">
        <ng-container *ngFor="let field of searchprofileConfig.config">
          <li *ngIf="field.visible">

            <!--'Elements.title'-->
            <app-searchprofile-title *ngIf="field.field === 'Elements.title'" [value]="searchprofileData[field.field]">
            </app-searchprofile-title>

            <!--'ObjectTypes.uid'-->
            <app-searchprofile-objecttypes *ngIf="field.field === 'ObjectTypes.uid'"
                                           [filter]="searchprofileData[field.field]"></app-searchprofile-objecttypes>

            <!--'Businessareas.uid'-->
            <app-searchprofile-businessareas *ngIf="field.field === 'Businessareas.uid'"
                                             [filter]="searchprofileData[field.field]"></app-searchprofile-businessareas>

            <!--'Productgroups.uid'-->
            <app-searchprofile-productgroups *ngIf="field.field === 'Productgroups.uid'"
                                             [filter]="searchprofileData[field.field]"></app-searchprofile-productgroups>

            <!--'Products.uid'-->
            <app-searchprofile-products *ngIf="field.field === 'Products.uid'"
                                        [filter]="searchprofileData[field.field]"></app-searchprofile-products>

            <!--'Elements.changelog_date'-->
            <app-searchprofile-changelog *ngIf="field.field === 'Elements.changelog_date'"
                                         [value]="searchprofileData[field.field]"></app-searchprofile-changelog>

            <!--'ElementsFileobjects[0].language_code'-->
            <app-searchprofile-languages *ngIf="field.field === 'ElementsFileobjects.language_code'"
                                         [filter]="searchprofileData[field.field]"></app-searchprofile-languages>

            <!--'Meta.id'-->
            <app-searchprofile-meta *ngIf="field.field === 'Meta.id'"
                                    [filterKey]="field.filterKey"
                                    [filter]="searchprofileData[field.field]"></app-searchprofile-meta>

          </li>
        </ng-container>
      </ol>
    </form>

    <p *ngIf="hasVisibleFilters()" class="clearAll">
      <a (click)="clearAll()">
        <app-locallang key="vsfiles.resetFilters"></app-locallang>
      </a>
    </p>


    <!-- Message: Not found -->
    <p *ngIf="!loading && !total" class="notFound">
      <app-locallang key="vsfiles.noResults"></app-locallang>
    </p>

    <!-- Progress bar -->
    <div *ngIf="loading" class="vsfiles-progressbar">
      <span><span class="vsfiles-progressbar--indicator"></span></span>
    </div>

    <!-- Results info -->
    <div *ngIf="paginationReady && total" class="vsfiles-results-info">
      <p *ngIf="fileobjects">
        <br/>
        <app-locallang key="vsfiles.results.info" [vars]="resultsInfo"></app-locallang>
      </p>
    </div>


    <!-- Results -->
    <ul *ngIf="fileobjects" class="downloads default">
      <ng-container *ngFor="let fileobject of fileobjects">
        <li *ngIf="fileobject.Objecttypes && fileobject.Objecttypes.render">
          <ng-container [ngSwitch]="fileobject.Objecttypes.render">

            <app-render-default *ngSwitchCase="'default'" [fileobject]="fileobject"></app-render-default>

            <app-render-video *ngSwitchCase="'video'" [fileobject]="fileobject"
                              [searchprofileConfig]="searchprofileConfig"></app-render-video>

            <ng-container *ngSwitchCase="'details'">
              <app-render-report *ngIf="!searchprofileConfig.disable_detail_view" [fileobject]="fileobject">
              </app-render-report>
              <app-render-default *ngIf="searchprofileConfig.disable_detail_view" [fileobject]="fileobject">
              </app-render-default>
            </ng-container>

            <app-render-default *ngSwitchCase="'images'" [fileobject]="fileobject"></app-render-default>

            <pre *ngSwitchDefault>Undefined render mode: {{fileobject.Objecttypes.render}}</pre>

          </ng-container>
        </li>
      </ng-container>
    </ul>

    <!-- Pagination -->
    <ng-container *ngIf="fileobjects && total > limit">
      <p>
        <app-locallang key="search.resultpages"></app-locallang>
      </p>

      <div class="vsfiles-pagination">
        <a [class]="getPaginatorClass(1)" (click)="load(1)">1</a>
        <a *ngIf="minPage() > 2">...</a>

        <ng-container *ngFor="let page of pages()">
          <a [class]="getPaginatorClass(page)" *ngIf="page >= minPage() && page <= maxPage()"
             (click)="load(page)">{{page}}</a>
        </ng-container>

        <a *ngIf="maxPage() < totalPages()-1">...</a>
        <a [class]="getPaginatorClass(totalPages())" (click)="load(totalPages())">{{totalPages()}}</a>

        <div class="selector">
          <ul>
            <li class="label">
              <a href="#">{{limit}} items per page</a>
            </li>
            <li *ngFor="let pagination of paginations">
              <a (click)="setPagination(pagination)">{{pagination}} items per page</a>
            </li>
          </ul>
        </div>
      </div>
    </ng-container>

  </div>

  <!-- Details -->
  <div *ngIf="renderService.showDetails" class="vsfiles--details">

    <app-render-report-detail [fileobject]="renderService.currentFileobject"></app-render-report-detail>

  </div>
</ng-container>
