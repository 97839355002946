import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export abstract class AbstractDataService {
  abstract LoadedEvent: EventEmitter<any>;
  abstract ChangedEvent: EventEmitter<any>;

  abstract changeSelection(data: any): void;
}

/**
 * IMPLEMENTATION RULES FOR SELECT2 COMPONENT WITH DEPENCENDY INJECTED SERVICE ABSTRACTION
 *
 * 1. Derive a service from `AbstractDataService`, e.g. `BusinessareaService`
 * 2. Add injectable abstract explicitly as provider in order to gain access to abstract in JavaScript runtime
 *    e.g. add `{provide: AbstractDataService, useClass: BusinessareaService}` to app.module.ts
 * 3. Create your component using CLI: `ng generate component elements/files-$type$/businessareas`
 * 4. Derive BusinessareasComponent from Select2Component: `export class BusinessareasComponent extends Select2Component {}`
 * 5. Overload `toSelect2Format()`
 * 6. Overload `toOutputFormat()` and `toInputFormat()` if necessary/desired.
 */
