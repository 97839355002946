<label>
  <span>
    <app-locallang key="vsfiles.filterColumn.Elements.title"></app-locallang>
  </span>

  <div class="search">
    <input type="text" [formControl]="InputControl"
           [placeholder]="locallangService.get('vsfiles.placeholder.Elements.title')"/>
  </div>
</label>
