import {EventEmitter, Injectable, Output} from '@angular/core';
import {ApiService} from "./api.service";
import {ElementsMeta} from "@verder-scientific/vs-files-client/dist/Models/elements-meta";

@Injectable({
  providedIn: 'root'
})
export class ElementMetumService {
  @Output() LoadedEvent: EventEmitter<ElementsMeta[]> = new EventEmitter();
  @Output() ChangedEvent: EventEmitter<ElementsMeta[]> = new EventEmitter();

  data: ElementsMeta[] = [];
  metaKey: string = '';

  constructor(
    public api: ApiService,
  ) {
  }

  load(): void {
    this.api.findElementsMeta()
      .then(data => this.LoadedEvent.emit(this.data = data as ElementsMeta[]));
  }

  getAll(): ElementsMeta[] {
    return this.data || [];
  }

  changeSelection(data: number[]): void {
    this.api.setElementMetaKeysFilter(this.metaKey, data as any as string[]);
    this.ChangedEvent.emit();
  }

  setMetaKey(key: string): void {
    this.metaKey = key;
  }
}
