import {Component, Input, OnInit} from '@angular/core';
import {Fileobjects} from '@verder-scientific/vs-files-client/dist/Models/fileobjects';
import {LanguageService} from 'src/app/data-services/language.service';
import {FilterSearchprofileService} from 'src/app/services/filter-searchprofile.service';

@Component({
  selector: 'app-render-report-detail',
  templateUrl: './render-report-detail.component.html',
  styleUrls: [
    '../render-default/render-default.component.scss',
    '../render-report/render-report.component.scss',
    './render-report-detail.component.scss',
  ]
})
export class RenderReportDetailComponent implements OnInit {
  @Input() fileobject!: Fileobjects;

  constructor(
    public languageService: LanguageService,
    public renderService: FilterSearchprofileService,
  ) {
  }

  ngOnInit(): void {
  }

}
