/* eslint-disable @angular-eslint/no-input-rename */
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Elements} from '@verder-scientific/vs-files-client/dist/Models/elements';
import {Folders} from '@verder-scientific/vs-files-client/dist/Models/folders';
import {Languages} from '@verder-scientific/vs-files-client/dist/Models/languages';
import {Objecttypes} from '@verder-scientific/vs-files-client/dist/Models/objecttypes';
import {ApiService} from 'src/app/data-services/api.service';
import {LanguageService} from 'src/app/data-services/language.service';
import {LocallangService} from 'src/app/data-services/locallang.service';
import {FilterSearchprofileService} from 'src/app/services/filter-searchprofile.service';
import {ElementsMeta} from "@verder-scientific/vs-files-client/dist/Models/elements-meta";

@Component({
  selector: 'app-files-classic',
  templateUrl: './files-classic.component.html',
  styleUrls: ['./files-classic.component.scss']
})
export class FilesClassicComponent implements OnInit {

  @Input('auth-token') authToken: string | undefined;
  @Input('project-key') projectKey!: string;
  @Input('language-code') languageCode = 'en';
  @Input('fold-box-behavior') foldBoxBehavior = 'open_all';
  @Input('fold-box-auto-open') foldBoxAutoOpen = '';
  @Input('detailed-view') detailedView = false;
  @Input('with-object-types') withObjectTypes!: string;
  @Input('with-ids') withIds!: string;
  @Input('with-fileobject-title') withFileobjectTitle!: string;
  @Input('with-business-areas') withBusinessAreas!: string;
  @Input('with-product-groups') withProductGroups!: string;
  @Input('with-products') withProducts!: string;
  @Input('with-preselected-language') withPreselectedLanguage!: string;

  @Output() LoadedEvent: EventEmitter<any> = new EventEmitter();
  loading = true;
  objectTypes: Objecttypes[] = [];

  constructor(
    public api: ApiService,
    public locallangService: LocallangService,
    public languageService: LanguageService,
    public renderService: FilterSearchprofileService,
  ) {
    this.LoadedEvent.subscribe(results => {
      this.objectTypes = results;
      this.loading = false;
      this.renderService.showObjectTypeTitle = false;

      if (this.detailedView) {
        this.renderService.backLink = false;
        if (this.objectTypes && this.objectTypes[0] && this.objectTypes[0].Fileobjects) {
          this.renderService.setCurrentFileobject(this.objectTypes[0].Fileobjects[0]);
        }
      }

      this.languageService.load();
    });
  }

  ngOnInit(): void {
    this.locallangService.load(this.projectKey, this.languageCode);
    this.languageService.languageCode = this.languageCode;

    if (this.withPreselectedLanguage) {
      this.renderService.preselectedLanguageCode = this.withPreselectedLanguage;
    }


    const client = this.api
      .setProjectKey(this.projectKey)
      .setLanguageCode(this.languageCode)
      .getClient()
      .setObjectTypes();

    if (this.withObjectTypes) {
      client.withObjectTypes(this.withObjectTypes.split(',') as any as number[]);
    }

    if (this.withIds) {
      client.withIds(this.withIds.split(',') as any as number[]);
    }

    if (this.withFileobjectTitle) {
      client.withFileobjectTitles(this.withFileobjectTitle.split(',') as any as string[]);
    }

    if (this.withBusinessAreas) {
      client.withBusinessAreas(this.withBusinessAreas.split(',') as any as number[]);
    }

    if (this.withProductGroups) {
      client.withProductGroups(this.withProductGroups.split(',') as any as number[]);
    }

    if (this.withProducts) {
      client.withProducts(this.withProducts.split(',') as any as number[]);
    }

    client.find()
      .select(new Objecttypes())
      .select(new Elements())
      .select(new ElementsMeta())
      .select(new Folders(), ['id', 'basename'])
      .select(new Languages())
      .getData()
      .then(results => this.LoadedEvent.emit(this.objectTypes = results));
  }

  getFoldboxClass(objectTypeIndex: number, objectType: Objecttypes) {
    const foldBoxAutoOpenTypes = this.foldBoxAutoOpen.split(',');
    const closedClass = foldBoxAutoOpenTypes.includes(objectType.title) ? '' : 'closed';

    switch (this.foldBoxBehavior) {
      case 'open_first':
        return 'foldBox ' + (objectTypeIndex > 0 ? closedClass : '');
        break;
      case 'open_all':
        return 'foldBox';
        break;
      case 'hide':
        return 'foldBox foldBox--hidden';
        break;
      default:
        return `foldBox ${closedClass}`;
    }
  }
}
