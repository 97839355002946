import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Fileobjects} from '@verder-scientific/vs-files-client/dist/Models/fileobjects';
import {LanguageService} from 'src/app/data-services/language.service';
import {FilterSearchprofileService} from 'src/app/services/filter-searchprofile.service';

@Component({
  selector: 'app-render-default',
  templateUrl: './render-default.component.html',
  styleUrls: [
    './render-default.component.scss',
  ]
})
export class ResultRenderDefaultComponent implements OnInit {
  @Input() fileobject!: Fileobjects;
  @ViewChild('filesDropdownOpener') filesDropdownOpener?: ElementRef<HTMLAnchorElement>;

  constructor(
    public languageService: LanguageService,
    public renderService: FilterSearchprofileService,
  ) {
  }

  ngOnInit(): void {
  }

  updateStackingContext() {
    if (this.filesDropdownOpener) {
      $(this.filesDropdownOpener.nativeElement).parentsUntil('.area.content')
        .last().parent().addClass('selector--fix-display-static-before')
        .next().addClass('selector--fix-display-static');
    }
  }
}
