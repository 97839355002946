<h2>
  <span [innerHTML]="renderService.getElementLanguage(fileobject.ElementsFileobjects).Element.title | safeHtml"></span>
</h2>

<div class="thumbnail">
  <img *ngIf="renderService.getThumbnail(renderService.getElementLanguage(fileobject.ElementsFileobjects))"
       src="{{renderService.getThumbnail(renderService.getElementLanguage(fileobject.ElementsFileobjects))}}"
       [alt]="renderService.getAlt(renderService.getElementLanguage(fileobject.ElementsFileobjects))"/>
</div>

<div class="information" *ngIf="renderService.getElementLanguage(fileobject.ElementsFileobjects).Element.description"
     [innerHTML]="renderService.getElementLanguage(fileobject.ElementsFileobjects).Element.description | safeHtml">
</div>

<div class="selector">
  <ul>
    <li class="label">
      <a href="#">
        <app-locallang key="download.link"></app-locallang>
      </a>
    </li>

    <ng-container *ngFor="let languageItem of languageService.languageSorting">
      <ng-container
        *ngFor="let elementsFileobject of renderService.getElementLanguageItems(fileobject.ElementsFileobjects, languageItem, ['import', 'upload'])">
        <li *ngIf="elementsFileobject.id">
          <a [href]="elementsFileobject.Element.url_download"
             (mousedown)="renderService.trackPageview(elementsFileobject)" target="_blank">

            [{{elementsFileobject.Element.extension}}]
            <app-locallang [key]="'language.' + elementsFileobject.Language.name"></app-locallang>

            <ng-container *ngIf="elementsFileobject.Element.filesize">
              ({{elementsFileobject.Element.filesize | filesize}})
            </ng-container>

            <!-- {{ renderService.getChangelogSuffix(elementsFileobject) }} -->

          </a>
        </li>
      </ng-container>
    </ng-container>

  </ul>
</div>

<div class="selector" *ngIf="renderService.hasElementType(fileobject.ElementsFileobjects, 'link')">
  <ul>
    <li class="label">
      <a href="#">
        <app-locallang key="downloads_dropdown_online"></app-locallang>
      </a>
    </li>

    <ng-container *ngFor="let languageItem of languageService.languageSorting">
      <ng-container
        *ngFor="let elementsFileobject of renderService.getElementLanguageItems(fileobject.ElementsFileobjects, languageItem, ['link'])">
        <li *ngIf="elementsFileobject.id">
          <a [href]="elementsFileobject.Element.path" target="_blank">
            <app-locallang [key]="'language.' + elementsFileobject.Language.name"></app-locallang>
          </a>
        </li>
      </ng-container>
    </ng-container>

  </ul>
</div>

<p *ngIf="renderService.backLink">
  <a class="detailLink" (click)="renderService.setCurrentFileobject()">
    <app-locallang [key]="'referencelist.back'"></app-locallang>
  </a>
</p>
