import {AfterViewInit, Component} from '@angular/core';
import {RandomService} from '../../../libs/random.service';
import {ConversionService} from '../../../libs/conversion.service';
import {Select2Component} from '../../../components/select2/select2.component';
import {Select2OptionData} from '../../../models/select2-option-data';
import {Languages} from '@verder-scientific/vs-files-client/dist/Models/languages';
import {LanguageService} from '../../../data-services/language.service';
import {LocallangService} from '../../../data-services/locallang.service';
import {AbstractDataService} from '../../../abstracts/abstract-data.service';

@Component({
  selector: 'app-searchprofile-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['../input.scss']
})
export class LanguagesComponent extends Select2Component implements AfterViewInit {
  constructor(
    public random: RandomService,
    public conversion: ConversionService,
    public locallangService: LocallangService,
    public dataService: LanguageService,
  ) {
    super(random, conversion, locallangService, dataService as AbstractDataService);
  }

  // ngAfterViewInit(): void {
  //   super.ngAfterViewInit();
  //   this.dataService.api.setLanguagesFilter(this.value);
  // }

  toSelect2Format(options: Languages[]): Select2OptionData[] {
    return options
      .filter(item => this.filter && this.filter.length ? this.filter.includes(item.code) : true)
      .map(item => ({
        id: item.code as any as string,
        text: this.locallangService.get('language.' + item.name)
          .trim(),
      }))
      .sort((left: Select2OptionData, right: Select2OptionData): number => (left.text > right.text) ? 1 : -1);
  }

  toInputFormat(value: any): string[] {
    return value;
  }

  toOutputFormat(data: string[]): any {
    return data;
  }
}
