import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ElementService} from '../../../data-services/element.service';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {DatepickerHeaderComponent} from 'src/app/components/datepicker-header/datepicker-header.component';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import * as moment from 'moment';
import {debounceTime} from 'rxjs/operators';
import {Elements} from '@verder-scientific/vs-files-client/dist/Models/elements';
import {LocallangService} from 'src/app/data-services/locallang.service';

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-searchprofile-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: [
    './changelog.component.scss',
    '../input.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class ChangelogComponent implements OnInit {

  @Input() value: any;
  range = new UntypedFormGroup({
    start: new UntypedFormControl(),
    end: new UntypedFormControl()
  });

  public InputControl = new UntypedFormControl(moment());
  datepickerHeaderComponent = DatepickerHeaderComponent;
  changelogDates: Elements[] = [];

  constructor(
    public locallangService: LocallangService,
    public dataService: ElementService,
  ) {
  }

  ngOnInit(): void {
    // this.dataService.api.setChangelogsRangeFilter(this.value);
    // this.dataService.LoadedEvent.subscribe(data => this.changelogDates = data);

    this.range.valueChanges
      .pipe(debounceTime(400))
      .subscribe(data => {
        const range = [
          data.start ? data.start.toDate() : null,
          data.end ? data.end.toDate() : null,
        ];
        this.dataService.changeChangelogsSelection(range);
      });
  }
}
