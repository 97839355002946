<label>
  <span>
    <app-locallang [key]="'backend.saleslibrary.meta.' + filterKey"></app-locallang>
  </span>

  <select [id]="id" [attr.data-placeholder]="locallangService.get('vsfiles.placeholder.meta.' + filterKey)"
          *ngIf="locallangService.isLoaded"></select>

</label>

