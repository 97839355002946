import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {LocallangService} from '../../data-services/locallang.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-locallang',
  templateUrl: './locallang.component.html',
  styleUrls: ['./locallang.component.scss']
})
export class LocallangComponent implements OnInit {
  public loaded = false;
  varsData: string[] = [];

  @Input() key!: string;
  @Input() vars!: Observable<string[]>;
  @Input('fallbackToPlaceholder') fallbackToPlaceholder = true;

  constructor(
    public locallangService: LocallangService,
    public changeDetector: ChangeDetectorRef,
  ) {
    this.loaded = locallangService.isLoaded();
    locallangService.LoadedEvent.subscribe(() => this.loaded = true);
  }

  ngOnInit(): void {
    if (this.vars) {
      this.vars.subscribe(varsData => {
        this.varsData = varsData;
        this.changeDetector.detectChanges();
      });
    }
  }
}
