import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ElementsFileobjects} from "@verder-scientific/vs-files-client/dist/Models/elements-fileobjects";
import {LanguageService} from "../../../../data-services/language.service";
import {FilterSearchprofileService} from "../../../../services/filter-searchprofile.service";
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {Fileobjects} from "@verder-scientific/vs-files-client/dist/Models/fileobjects";

@Component({
  selector: 'app-render-video-detail',
  templateUrl: './render-video-detail.component.html',
  styleUrls: ['./render-video-detail.component.scss']
})
export class RenderVideoDetailComponent implements OnInit {
  @Input() fileobject!: Fileobjects;
  @Input() elementsFileobject!: ElementsFileobjects;
  @Input() isDetailView = false;
  @Input() playingVideo = false;
  @Input() playingVideoId = 0;
  playRemoteVideo = true;
  isRemoteVideoAvailable = false;
  @ViewChild('video')
  video!: ElementRef;
  localStorageItem = 'vsfiles:playRemoteVideo';

  constructor(
    public languageService: LanguageService,
    public renderService: FilterSearchprofileService,
    public sanitizer: DomSanitizer,
  ) {
  }

  ngOnInit() {
    this.renderService.DownloadSelectedEvent.subscribe(elementsFileobject => {
      if (this.fileobject.id === elementsFileobject.fileobject_id) {
        this.isRemoteVideoAvailable = this.getRemoteVideoLink(elementsFileobject) as boolean;
        if (this.isRemoteVideoAvailable) {
          this.playRemoteVideo = JSON.parse(localStorage.getItem(this.localStorageItem) || 'true') || true;
        }
        console.log('playRemoteVideo', this.playRemoteVideo);
        this.elementsFileobject = elementsFileobject
        this.playVideo(elementsFileobject);
      }
    });
  }

  isPlayingVideo(elementsFileobject: ElementsFileobjects): boolean {
    return this.playingVideo && this.playingVideoId === elementsFileobject.id;
  }

  playVideo(elementsFileobject: ElementsFileobjects): void {
    $('body').addClass('videobox__opened');
    this.renderService.trackPageview(elementsFileobject);
    this.playingVideo = true;
    this.playingVideoId = elementsFileobject.id;

    if (!this.playRemoteVideo) {
      this.video.nativeElement.url = elementsFileobject.Element.url_download;
      this.video.nativeElement.load();
      this.video.nativeElement.play();
    }
  }

  stopVideo(): void {
    $('body').removeClass('videobox__opened');
    this.playingVideo = false;
  }

  isPlayingRemoteVideo(): boolean {
    return this.playRemoteVideo;
  }

  getRemoteVideoLink(elementsFileobject: ElementsFileobjects): SafeResourceUrl | null {
    if (!elementsFileobject.Element.ElementsMeta) {
      return null;
    }

    const meta = elementsFileobject.Element.ElementsMeta.find(e => e.metum_id === 'remote_video_link');

    if (meta) {
      // https://www.youtube-nocookie.com/embed/BCxylpcbrwk
      const url = meta.value
        .replace(/www.youtube.com\/watch\/?\?v=/, 'www.youtube-nocookie.com/embed/');

      return this.sanitizer.bypassSecurityTrustResourceUrl(url + '?rel=0&autoplay=1');
    }

    return null;
  }

  toggleRemoteVideo(): void {
    this.playRemoteVideo = !this.playRemoteVideo;
    localStorage.setItem(this.localStorageItem, JSON.stringify(this.playRemoteVideo));
  }

  requestFullscreen() {
    const elem = document.getElementById('videobox__fullscreen');

    if (elem) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
        // @ts-ignore: Unreachable code error
      } else if (elem.webkitRequestFullscreen) { /* Safari */
        // @ts-ignore: Unreachable code error
        elem.webkitRequestFullscreen();
        // @ts-ignore: Unreachable code error
      } else if (elem.msRequestFullscreen) { /* IE11 */
        // @ts-ignore: Unreachable code error
        elem.msRequestFullscreen();
      }
    }
  }
}
