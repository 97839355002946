import {AfterViewInit, Component} from '@angular/core';
import {RandomService} from '../../../libs/random.service';
import {ConversionService} from '../../../libs/conversion.service';
import {Select2Component} from '../../../components/select2/select2.component';
import {Select2OptionData} from '../../../models/select2-option-data';
import {Productgroups} from '@verder-scientific/vs-files-client/dist/Models/productgroups';
import {ProductgroupService} from '../../../data-services/productgroup.service';
import {LocallangService} from '../../../data-services/locallang.service';
import {AbstractDataService} from '../../../abstracts/abstract-data.service';

@Component({
  selector: 'app-searchprofile-productgroups',
  templateUrl: './productgroups.component.html',
  styleUrls: ['../input.scss']
})
export class ProductgroupsComponent extends Select2Component implements AfterViewInit {
  constructor(
    public random: RandomService,
    public conversion: ConversionService,
    public locallangService: LocallangService,
    public dataService: ProductgroupService,
  ) {
    super(random, conversion, locallangService, dataService as AbstractDataService);
  }

  // ngAfterViewInit(): void {
  //   super.ngAfterViewInit();
  //   this.dataService.api.setProductgroupsFilter(this.value);
  // }

  toSelect2Format(options: Productgroups[]): Select2OptionData[] {
    return options
      .filter(item => this.filter && this.filter.length ? this.conversion.toNumbers(this.filter).includes(item.uid) : true)
      .map(item => ({
        id: item.uid as any as string,
        text: (item.apply_locallang ? this.locallangService.get('product_group.' + item.uid) : item.group_name)
          .trim(),
        top: item.hidden_in_navigation,
      }))
      .sort((n1, n2) => {
        if (n1.top || n2.top) {
          return 0;
        }
        if (n1.text > n2.text) {
          return 1;
        }
        if (n1.text < n2.text) {
          return -1;
        }
        return 0;
      });
  }
}
