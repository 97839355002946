<div class="thumbnail openNearestSelector">
  <img *ngIf="renderService.getThumbnail(renderService.getElementLanguage(fileobject.ElementsFileobjects))"
       src="{{renderService.getThumbnail(renderService.getElementLanguage(fileobject.ElementsFileobjects))}}"
       [alt]="renderService.getAlt(renderService.getElementLanguage(fileobject.ElementsFileobjects))"/>
</div>

<div class="information">

  <h2 class="text-left s-small w-bold">
    <span
      [innerHTML]="renderService.getElementLanguage(fileobject.ElementsFileobjects).Element.title | safeHtml"></span>

    <span class="label" *ngIf="renderService.showObjectTypeTitle">
      <app-locallang [key]="'type.' + fileobject.Objecttypes.title"></app-locallang>
    </span>
  </h2>

  <small *ngIf="renderService.getElementLanguage(fileobject.ElementsFileobjects).Element.description"
         [innerHTML]="renderService.getElementLanguage(fileobject.ElementsFileobjects).Element.description | safeHtml">
  </small>

  <a class="detailLink" (click)="openReportDetails()">
    <app-locallang [key]="'detailLink'"></app-locallang>
  </a>

</div>
