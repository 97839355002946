<label>
  <span>
    <app-locallang key="vsfiles.filterColumn.Elements.changelog_date"></app-locallang>
  </span>

  <!-- <div class="range">
    <div>
      <input [matDatepicker]="picker_start" placeholder="Start date" (focus)="picker_start.open()">
      <mat-datepicker #picker_start [calendarHeaderComponent]="datepickerHeaderComponent" startView="year">
      </mat-datepicker>
    </div>

    <div>-</div>

    <div>
      <input [matDatepicker]="picker_end" placeholder="End date" (focus)="picker_end.open()">
      <mat-datepicker #picker_end [calendarHeaderComponent]="datepickerHeaderComponent" startView="month">
      </mat-datepicker>
    </div>
  </div> -->

  <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
    <input matStartDate formControlName="start"
           [attr.placeholder]="locallangService.get('vsfiles.placeholder.changelog.start')" (focus)="picker.open()">
    <input matEndDate formControlName="end"
           [attr.placeholder]="locallangService.get('vsfiles.placeholder.changelog.end')" (focus)="picker.open()">
  </mat-date-range-input>
  <mat-date-range-picker #picker [calendarHeaderComponent]="datepickerHeaderComponent"></mat-date-range-picker>

</label>
