import {Injector, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {createCustomElement} from '@angular/elements';
// import {AppComponent} from './app.component';
import {FilesSearchprofileComponent} from './elements/files-searchprofile/files-searchprofile.component';
import {FilesClassicComponent} from './elements/files-classic/files-classic.component';
import {LocallangComponent} from './components/locallang/locallang.component';
import {LocallangService} from './data-services/locallang.service';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ObjecttypesComponent} from './elements/files-searchprofile/filter-objecttypes/objecttypes.component';
import {BusinessareasComponent} from './elements/files-searchprofile/filter-businessareas/businessareas.component';
import {ProductgroupsComponent} from './elements/files-searchprofile/filter-productgroups/productgroups.component';
import {ProductsComponent} from './elements/files-searchprofile/filter-products/products.component';
import {ChangelogComponent} from './elements/files-searchprofile/filter-changelog/changelog.component';
import {LanguagesComponent} from './elements/files-searchprofile/filter-languages/languages.component';
import {TitleComponent} from './elements/files-searchprofile/filter-title/title.component';
import {NgxFilesizeModule} from 'ngx-filesize';
import {Select2Component} from './components/select2/select2.component';
import {AbstractDataService} from './abstracts/abstract-data.service';
import {BusinessareaService} from './data-services/businessarea.service';
import {ElementService} from './data-services/element.service';
import {LanguageService} from './data-services/language.service';
import {ObjecttypeService} from './data-services/objecttype.service';
import {ProductService} from './data-services/product.service';
import {ProductgroupService} from './data-services/productgroup.service';
import {FilterSearchprofileService} from './services/filter-searchprofile.service';
import {registerLocaleData} from '@angular/common';
import {SafeHtmlPipe} from './pipes/safe-html.pipe';
import {
  ResultRenderDefaultComponent
} from './elements/files-searchprofile/result/render-default/render-default.component';
import {RenderReportComponent} from './elements/files-searchprofile/result/render-report/render-report.component';
import {
  RenderReportDetailComponent
} from './elements/files-searchprofile/result/render-report-detail/render-report-detail.component';

import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatIconModule} from '@angular/material/icon';

import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {RenderVideoComponent} from './elements/files-searchprofile/result/render-video/render-video.component';
import {RenderImageComponent} from './elements/files-searchprofile/result/render-image/render-image.component';
import {DownloadSelectorComponent} from './components/download-selector/download-selector.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {DatepickerHeaderComponent} from './components/datepicker-header/datepicker-header.component';
import {
  RenderVideoDetailComponent
} from './elements/files-searchprofile/result/render-video-detail/render-video-detail.component';
import { MetaComponent } from './elements/files-searchprofile/filter-meta/meta.component';

registerLocaleData(localeDe, localeDeExtra);

@NgModule({
  declarations: [
    // AppComponent,
    LocallangComponent,
    FilesSearchprofileComponent,
    FilesClassicComponent,
    ObjecttypesComponent,
    BusinessareasComponent,
    ProductgroupsComponent,
    ProductsComponent,
    ChangelogComponent,
    LanguagesComponent,
    TitleComponent,
    Select2Component,
    SafeHtmlPipe,
    ResultRenderDefaultComponent,
    RenderReportComponent,
    RenderReportDetailComponent,
    RenderVideoComponent,
    RenderImageComponent,
    DownloadSelectorComponent,
    DatepickerHeaderComponent,
    RenderVideoDetailComponent,
    MetaComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgxFilesizeModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatIconModule,
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'de'},
    LocallangService,
    {provide: AbstractDataService, useClass: BusinessareaService},
    {provide: AbstractDataService, useClass: ElementService},
    {provide: AbstractDataService, useClass: LanguageService},
    {provide: AbstractDataService, useClass: ObjecttypeService},
    {provide: AbstractDataService, useClass: ProductService},
    {provide: AbstractDataService, useClass: ProductgroupService},
    {provide: AbstractDataService, useClass: FilterSearchprofileService},
  ]
})
export class FilesAppModule {
  constructor(
    private injector: Injector
  ) {
    customElements.define('app-files-classic',
      createCustomElement(FilesClassicComponent, {injector})
    );
    customElements.define('app-files-searchprofile',
      createCustomElement(FilesSearchprofileComponent, {injector})
    );
  }

  // noinspection JSUnusedGlobalSymbols
  ngDoBootstrap(): void {
  }
}
