<div class="selector">
  <ul>
    <li class="label">
      <a (click)="toggleSelector()">
        <app-locallang [key]="label"></app-locallang>
      </a>
    </li>

    <ng-container *ngIf="showSelector">
      <ng-container *ngFor="let languageItem of languageService.languageSorting">
        <ng-container
          *ngFor="let elementsFileobject of renderService.getElementLanguageItems(fileobject.ElementsFileobjects, languageItem, ['import', 'upload'])">
          <li *ngIf="elementsFileobject.id">
            <a (click)="selectDownload(elementsFileobject)">

              <app-locallang [key]="'language.' + elementsFileobject.Language.name"></app-locallang>

            </a>
          </li>
        </ng-container>
      </ng-container>
    </ng-container>

  </ul>
</div>

