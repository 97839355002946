import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Fileobjects} from '@verder-scientific/vs-files-client/dist/Models/fileobjects';
import {LanguageService} from 'src/app/data-services/language.service';
import {FilterSearchprofileService} from 'src/app/services/filter-searchprofile.service';
import {SearchprofileConfig} from '../../../../models/searchprofile-config';

@Component({
  selector: 'app-render-video',
  templateUrl: './render-video.component.html',
  styleUrls: [
    '../render-default/render-default.component.scss',
    './render-video.component.scss',
  ]
})
export class RenderVideoComponent implements OnInit {
  @Input() fileobject!: Fileobjects;
  @Input() searchprofileConfig!: SearchprofileConfig;
  @Input() playingVideo = false;
  @Input() playingVideoId = 0;

  constructor(
    public languageService: LanguageService,
    public renderService: FilterSearchprofileService,
    public sanitizer: DomSanitizer,
  ) {
  }

  ngOnInit(): void {
  }
}
