import {Component, Input, OnInit} from '@angular/core';
import {Fileobjects} from '@verder-scientific/vs-files-client/dist/Models/fileobjects';
import {FilterSearchprofileService} from 'src/app/services/filter-searchprofile.service';

@Component({
  selector: 'app-render-report',
  templateUrl: './render-report.component.html',
  styleUrls: [
    '../render-default/render-default.component.scss',
    './render-report.component.scss',
  ]
})
export class RenderReportComponent implements OnInit {
  @Input() fileobject!: Fileobjects;

  constructor(
    public renderService: FilterSearchprofileService,
  ) {
  }

  ngOnInit(): void {
  }

  openReportDetails(): void {
    this.renderService.setCurrentFileobject(this.fileobject);
    window.scrollTo({top: 0});
  }
}
