import {EventEmitter, Injectable, Output} from '@angular/core';
import {ApiService} from './api.service';
import {SearchprofileConfig} from '../models/searchprofile-config';

@Injectable({
  providedIn: 'root'
})
export class SearchprofileService {

  @Output() LoadedEvent: EventEmitter<SearchprofileConfig> = new EventEmitter();

  constructor(
    public api: ApiService,
  ) {
  }

  get(searchprofileId: number): void {
    this.api.getClient()
      .setSearchprofiles()
      .withSearchprofileId(searchprofileId)
      .find()
      .first()
      .getData()
      .then((result: any) => {
        if (!result.length) {
          throw new Error('Invalid searchprofileId, no config returned!');
        }

        this.LoadedEvent.emit(result[0]);
      });
  }
}
