import {AfterViewInit, Component} from '@angular/core';
import {RandomService} from '../../../libs/random.service';
import {ConversionService} from '../../../libs/conversion.service';
import {Select2Component} from '../../../components/select2/select2.component';
import {Select2OptionData} from '../../../models/select2-option-data';
import {Objecttypes} from '@verder-scientific/vs-files-client/dist/Models/objecttypes';
import {ObjecttypeService} from '../../../data-services/objecttype.service';
import {LocallangService} from '../../../data-services/locallang.service';
import {AbstractDataService} from '../../../abstracts/abstract-data.service';

@Component({
  selector: 'app-searchprofile-objecttypes',
  templateUrl: './objecttypes.component.html',
  styleUrls: ['../input.scss']
})
export class ObjecttypesComponent extends Select2Component implements AfterViewInit {
  constructor(
    public random: RandomService,
    public conversion: ConversionService,
    public locallangService: LocallangService,
    public dataService: ObjecttypeService,
  ) {
    super(random, conversion, locallangService, dataService as AbstractDataService);
  }

  // ngAfterViewInit(): void {
  //   super.ngAfterViewInit();
  //   this.dataService.api.setObjecttypeFilter(this.value);
  // }

  toSelect2Format(options: Objecttypes[]): Select2OptionData[] {
    return options
      .filter(item => this.filter && this.filter.length ? this.filter.includes(item.id) : true)
      .filter(item => item.title)
      .map(item => ({
        id: item.id as any as string,
        text: this.locallangService.get('type.' + item.title)
          .replace(/<\/?[^>]+(>|$)/g, ' ')
          .trim(),
      }))
      .sort((left: Select2OptionData, right: Select2OptionData): number => (left.text > right.text) ? 1 : -1);
  }
}
