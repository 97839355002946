import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class RandomService {

  constructor() {
  }

  getId(): string {
    return `id_${this.getString()}`;
  }

  getString(length?: number): string {
    return Math.random()
      .toString(16)
      .substr(2, length || 14);
  }
}
