import {AfterViewInit, Component} from '@angular/core';
import {RandomService} from '../../../libs/random.service';
import {ConversionService} from '../../../libs/conversion.service';
import {Select2Component} from '../../../components/select2/select2.component';
import {Select2OptionData} from '../../../models/select2-option-data';
import {Businessareas} from '@verder-scientific/vs-files-client/dist/Models/businessareas';
import {BusinessareaService} from '../../../data-services/businessarea.service';
import {AbstractDataService} from '../../../abstracts/abstract-data.service';
import {LocallangService} from '../../../data-services/locallang.service';

@Component({
  selector: 'app-searchprofile-businessareas',
  templateUrl: './businessareas.component.html',
  styleUrls: ['../input.scss']
})
export class BusinessareasComponent extends Select2Component implements AfterViewInit {
  constructor(
    public random: RandomService,
    public conversion: ConversionService,
    public locallangService: LocallangService,
    public dataService: BusinessareaService,
  ) {
    super(random, conversion, locallangService, dataService as AbstractDataService);
  }

  toSelect2Format(options: Businessareas[]): Select2OptionData[] {
    return options
      .filter(item => this.filter && this.filter.length ? this.conversion.toNumbers(this.filter).includes(item.uid) : true)
      .map(item => ({
        id: item.uid as any as string,
        text: (item.apply_locallang ? this.locallangService.get('business_area.' + item.uid) : item.area_name)
          .trim(),
        top: item.hidden_in_navigation,
      }))
      .sort((n1, n2) => {
        if (n1.top || n2.top) {
          return 0;
        }
        if (n1.text > n2.text) {
          return 1;
        }
        if (n1.text < n2.text) {
          return -1;
        }
        return 0;
      });
  }
}
