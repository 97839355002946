import {EventEmitter, Injectable, Output} from '@angular/core';
import {ApiService} from './api.service';
import {Languages} from '@verder-scientific/vs-files-client/dist/Models/languages';
import {LocallangService} from './locallang.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  @Output() LoadedEvent: EventEmitter<Languages[]> = new EventEmitter();
  @Output() ChangedEvent: EventEmitter<Languages[]> = new EventEmitter();

  data: Languages[] = [];
  primaryLanguageCodes: string[] = [];
  languageSorting: string[] = [];
  languageCode = 'en';

  constructor(
    public api: ApiService,
    public locallangService: LocallangService,
  ) {
  }

  load(): void {
    this.api.findLanguages()
      .then(data => {
        this.data = data as Languages[];
        this.createLanguageSortingPattern();
        this.LoadedEvent.emit(this.data);
      });
  }

  getAll(): Languages[] {
    return this.data || [];
  }

  changeSelection(data: string[]): void {
    this.api.setLanguagesFilter(data);
    this.ChangedEvent.emit();
  }

  private createLanguageSortingPattern(): void {
    if (!this.locallangService.isLoaded() || this.languageSorting.length) {
      return;
    }

    const languages = this.getAll();
    this.primaryLanguageCodes = [this.languageCode, 'en', 'de'];
    const secondaries: string[] = [];
    const sortingStack: any[] = [];

    switch (this.languageCode) {
      case 'de':
        this.primaryLanguageCodes = ['de', 'en'];
        break;
      case 'en':
        this.primaryLanguageCodes = ['en', 'de'];
        break;
    }

    languages.forEach(item => {
      if (this.primaryLanguageCodes.includes(item.code)) {
        return;
      }

      const label = this.locallangService.get(`language.${item.name}`, false);

      if (label) {
        sortingStack.push({code: item.code, label});
      }
    });

    sortingStack
      .sort((a, b) => a.label.localeCompare(b.label))
      .forEach(item => secondaries.push(item.code));

    this.languageSorting = this.primaryLanguageCodes.concat(secondaries);
  }

}
