<ng-container *ngIf="!loading">
  <div *ngIf="!renderService.showDetails" class="vsfiles">

    <div [class]="getFoldboxClass(objectTypeIndex, objectType)"
         *ngFor="let objectType of objectTypes; let objectTypeIndex = index">

      <h4 class="text-left">
        <a [id]="'downloadType' + objectType.id "></a>
        <app-locallang [key]="'type.' + objectType.title"></app-locallang>
        ({{objectType.Fileobjects.length}})
      </h4>

      <div class="foldBoxContent">

        <ul *ngIf="objectType.Fileobjects" class="downloads default">
          <li *ngFor="let fileobject of objectType.Fileobjects">

            <ng-container *ngIf="objectType.render === 'default'">
              <app-render-default [fileobject]="fileobject"></app-render-default>
            </ng-container>

            <ng-container *ngIf="objectType.render === 'video'">
              <app-render-video [fileobject]="fileobject"></app-render-video>
            </ng-container>

            <ng-container *ngIf="objectType.render === 'details'">
              <app-render-report [fileobject]="fileobject"></app-render-report>
            </ng-container>

            <ng-container *ngIf="objectType.render === 'images'">
              <app-render-default [fileobject]="fileobject"></app-render-default>
            </ng-container>

          </li>
        </ul>

      </div>
    </div>
  </div>

  <div *ngIf="renderService.showDetails" class="vsfiles--details">

    <ng-container *ngIf="objectTypes[0].render === 'video'">
      <app-render-video-detail [elementsFileobject]="renderService.getCurrentElementsFileobject()"
                               [fileobject]="renderService.currentFileobject"
                               [isDetailView]="true"></app-render-video-detail>
    </ng-container>

    <ng-container *ngIf="objectTypes[0].render !== 'video'">
      <app-render-report-detail [fileobject]="renderService.currentFileobject"></app-render-report-detail>
    </ng-container>

  </div>
</ng-container>

