import {AfterViewInit, Component, Input} from '@angular/core';
import {RandomService} from "../../../libs/random.service";
import {ConversionService} from "../../../libs/conversion.service";
import {Select2Component} from "../../../components/select2/select2.component";
import {Select2OptionData} from "../../../models/select2-option-data";
import {LocallangService} from "../../../data-services/locallang.service";
import {AbstractDataService} from "../../../abstracts/abstract-data.service";
import {ElementMetumService} from "../../../data-services/element-metum.service";
import {ElementsMeta} from "@verder-scientific/vs-files-client/dist/Models/elements-meta";

@Component({
  selector: 'app-searchprofile-meta',
  templateUrl: './meta.component.html',
  styleUrls: ['../input.scss']
})
export class MetaComponent extends Select2Component implements AfterViewInit {
  @Input() filterKey = '';

  constructor(
    public random: RandomService,
    public conversion: ConversionService,
    public locallangService: LocallangService,
    public dataService: ElementMetumService,
  ) {
    super(random, conversion, locallangService, dataService as AbstractDataService);
  }

  changeSelection(): void {
    this.dataService.setMetaKey(this.filterKey);

    /* cache select2 value */
    let selection
      = this.value
      = this.toOutputFormat(this.control.val() as string[]);

    if (this.filter && this.filter.length && (!this.value || (this.value && !this.value.length))) {
      selection = [...this.filter];
    }

    /* trigger service function */
    this.dataService.changeSelection(selection);
  }

  toSelect2Format(options: ElementsMeta[]): Select2OptionData[] {
    return options
      .filter(item => this.filter && this.filter.length ? this.filter.includes(item.value) : true)
      .filter(item => item.metum_id === this.filterKey)
      .map(item => ({
        id: item.value as any as string,
        text: this.locallangService.apply(item.value as any as string),
      }))
      .sort((left: Select2OptionData, right: Select2OptionData): number => (left.text > right.text) ? 1 : -1);
  }

  toInputFormat(value: any): string[] {
    return value;
  }

  toOutputFormat(data: string[]): any {
    return data;
  }
}

