import {EventEmitter, Injectable, Output} from '@angular/core';
import {ApiService} from './api.service';
import {Elements} from '@verder-scientific/vs-files-client/dist/Models/elements';

@Injectable({
  providedIn: 'root'
})
export class ElementService {
  @Output() LoadedEvent: EventEmitter<Elements[]> = new EventEmitter();
  @Output() ChangedEvent: EventEmitter<Elements[]> = new EventEmitter();

  data: Elements[] = [];

  constructor(
    public api: ApiService,
  ) {
  }

  loadChangelogDates(): void {
    this.api.findChangelogDates()
      .then(data => this.LoadedEvent.emit(this.data = data as Elements[]));
  }

  getAll(): Elements[] {
    return this.data || [];
  }

  changeChangelogsSelection(data: Date[]): void {
    this.api.setChangelogsRangeFilter(data);
    this.ChangedEvent.emit();
  }

  changeTitleFilter(data: string): void {
    this.api.setTitleFilter(data);
    this.ChangedEvent.emit();
  }

  changeSelection(data: string[]): void {
    this.api.setChangelogsFilter(data);
    this.ChangedEvent.emit();
  }
}
