/* eslint-disable @angular-eslint/no-input-rename */
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ApiService} from '../../data-services/api.service';
import {SearchprofileService} from '../../data-services/searchprofile.service';
import {SearchprofileConfig} from '../../models/searchprofile-config';
import {LocallangService} from '../../data-services/locallang.service';
import {LanguageService} from '../../data-services/language.service';
import {ObjecttypeService} from '../../data-services/objecttype.service';
import {ProductService} from '../../data-services/product.service';
import {ProductgroupService} from '../../data-services/productgroup.service';
import {BusinessareaService} from '../../data-services/businessarea.service';
import {Fileobjects} from '@verder-scientific/vs-files-client/dist/Models/fileobjects';
import {ElementService} from '../../data-services/element.service';
import {ConversionService} from '../../libs/conversion.service';
import {FilterSearchprofileService} from 'src/app/services/filter-searchprofile.service';
import {ElementMetumService} from "../../data-services/element-metum.service";

@Component({
  selector: 'app-files-searchprofile',
  templateUrl: './files-searchprofile.component.html',
  styleUrls: ['./files-searchprofile.component.scss']
})
export class FilesSearchprofileComponent implements OnInit {

  @Input('auth-token') authToken: string | undefined;
  @Input('project-key') projectKey!: string;
  @Input('language-code') languageCode = 'en';
  @Input('id') id!: number;
  @Input('hide-filters') hideFilters!: string;
  @Input('filter-value-business-area-id') filterValueBusinessAreaId!: string;
  @Input('filter-value-product-group-id') filterValueProductGroupId!: string;
  @Input('filter-value-product-akz') filterValueProductAkz!: string;
  @Input('filter-value-language-code') filterValueLanguageCode!: string;
  @Input('hide-objecttype-title') hideObjectTypeTitle!: boolean;

  @Output() LoadedEvent: EventEmitter<any> = new EventEmitter();
  @Output() resultsInfo: EventEmitter<string[]> = new EventEmitter();

  searchprofileConfig!: SearchprofileConfig;
  searchprofileData: any = {};
  searchprofileOptions: any = {};
  fileobjects: Fileobjects[] = [];
  loading = true;
  locallangReady = false;
  paginationReadyLevel = 0;
  paginationReady = false;
  languageSorting: string[] = [];
  total = 0;
  limit = 20;
  page = 1;
  paginations = [20, 50, 100];

  constructor(
    public api: ApiService,
    public conversion: ConversionService,
    public searchprofileService: SearchprofileService,
    public locallangService: LocallangService,
    public renderService: FilterSearchprofileService,
    /* Filter related services */
    public businessareaService: BusinessareaService,
    public objecttypeService: ObjecttypeService,
    public productgroupService: ProductgroupService,
    public productService: ProductService,
    public languageService: LanguageService,
    public elementService: ElementService,
    public elementMetumService: ElementMetumService,
  ) {
    this.searchprofileService.LoadedEvent.subscribe(searchprofileConfig => {
      this.searchprofileConfig = searchprofileConfig;
      this.searchprofileConfig.config.forEach(item => {
        if (item.presetFilterValue) {
          if (Array.isArray(item.presetFilterValue)) {
            this
              .applyToPlaceholder({
                filterValues: item.presetFilterValue,
                key: '###business_area###',
                value: this.filterValueBusinessAreaId
              })
              .applyToPlaceholder({
                filterValues: item.presetFilterValue,
                key: '###product_group###',
                value: this.filterValueProductGroupId
              })
              .applyToPlaceholder({
                filterValues: item.presetFilterValue,
                key: '###product###',
                value: this.filterValueProductAkz
              })
              .applyToPlaceholder({
                filterValues: item.presetFilterValue,
                key: '###language###',
                value: this.filterValueLanguageCode
              });
          }

          this.searchprofileData[item.field] = item.presetFilterValue;
        }

        if (item.options) {
          this.searchprofileOptions[item.field] = item.options;
        }
      });

      this.api.setTitleFilter(this.searchprofileData['Elements.title']);
      this.api.setObjecttypeFilter(this.searchprofileData['ObjectTypes.uid']);
      this.api.setBusinessareasFilter(this.conversion.toNumbers(this.searchprofileData['Businessareas.uid']));
      this.api.setProductgroupsFilter(this.conversion.toNumbers(this.searchprofileData['Productgroups.uid']));
      this.api.setProductAkzsFilter(this.searchprofileData['Products.uid']);
      this.api.setChangelogsFilter(this.searchprofileData['Elements.changelog_date']);
      this.api.setLanguagesFilter(this.searchprofileData['ElementsFileobjects.language_code']);

      if (this.searchprofileOptions.hasOwnProperty('Products.uid')) {
        // if (this.searchprofileOptions['Products.uid'].allCurrentProducts) {
        //   this.api.setCurrentProducts(true);
        // }

        if (this.filterValueProductAkz) {
          this.api.setProductAkzsFilter(this.searchprofileData['Products.uid']);
        } else {
          if (this.searchprofileOptions['Products.uid'].allDiscontinuedProducts) {
            this.api.setDiscontinuedProducts(true);
          } else {
            this.api.setCurrentProducts(true);
          }
        }
      }

      this.load();
    });

    this.renderService.ViewSwitchedEvent.subscribe(() => this.onPaginationReady());
    this.locallangService.LoadedEvent.subscribe(() => this.onLocallangReady());

    this.objecttypeService.ChangedEvent.subscribe(() => this.load());
    this.businessareaService.ChangedEvent.subscribe(() => this.load());
    this.productgroupService.ChangedEvent.subscribe(() => this.load());
    this.productService.ChangedEvent.subscribe(() => this.load());
    this.elementService.ChangedEvent.subscribe(() => this.load());
    this.languageService.ChangedEvent.subscribe(() => this.load());
    this.elementMetumService.ChangedEvent.subscribe(() => this.load());

    this.LoadedEvent.subscribe(results => {
      this.fileobjects = results;
      this.loading = false;

      if (this.total) {
        if (!this.isHiddenFilters()) {
          this.elementMetumService.load();
          this.objecttypeService.load();
          this.businessareaService.load();
          this.productgroupService.load();
          this.productService.load();
          this.elementService.loadChangelogDates();
          this.languageService.load();
        }

        this.onPaginationReady();
      }
    });

    // setTimeout(() => {
    //   this.locallangService.load();
    // }, 2000);
  }

  setPagination(pagination: number): void {
    this.limit = pagination;
    this.page = 1;
    this.fileobjects = [];
    this.load();
  }

  isHiddenFilters(): boolean {
    return !(!this.hideFilters || ['false', 'no', '0', ''].includes(this.hideFilters));
  }

  ngOnInit(): void {
    this.languageService.languageCode = this.languageCode;

    if (!this.id) {
      throw new Error(`Mandatory attribute 'id' missing!`);
    }

    if (this.hideObjectTypeTitle) {
      this.renderService.showObjectTypeTitle = false;
    }

    // Load Locallang files
    this.locallangService.load(this.projectKey, this.languageCode);

    this.api
      .setProjectKey(this.projectKey)
      .setLanguageCode(this.languageCode)
      .setAuthToken(this.authToken);

    this.searchprofileService.get(this.id);
  }

  load(page?: number | boolean): void {
    this.loading = true;

    if (typeof page !== 'boolean') {
      this.page = page || 1;
    }

    this.api
      .setLimit(this.limit)
      .setPage(this.page);

    if (page !== false) {
      this.api.find()
        .then(results => {
          this.total = results.total;
          this.LoadedEvent.emit(results.data);
        });
    }

    this.scroll();
  }

  scroll(): void {
    const currentOffset = $(document).scrollTop();
    if (currentOffset && currentOffset > 100) {
      const filesUiOffset = $("app-files-searchprofile").offset();
      if (filesUiOffset) {
        $("html, body").animate({scrollTop: filesUiOffset.top - 60}, 500);
      }
    }
    // $("app-files-searchprofile").animate({ scrollTop: 0 }, 500);
  }

  pages(): any[] {
    const n = this.totalPages();
    const pages = [...Array(n).keys()];
    pages.shift();
    return pages;
  }

  totalPages(): number {
    return Math.ceil(this.total / this.limit);
  }

  minPage(): number {
    let minPage = 2;

    if (this.page < 6) {
      return minPage;
    }

    if (this.page > this.totalPages() - 5) {
      minPage = this.totalPages() - 9;

      return minPage > 2 ? minPage : 2;
    }

    return this.page - 4;
  }

  maxPage(): number {
    if (this.page > this.totalPages() - 6) {
      return this.totalPages() - 1;
    }

    if (this.totalPages() < 11) {
      return this.totalPages();
    }

    if (this.page < 5) {
      return 10;
    }

    return this.page + 4;
  }

  getPaginatorClass(page: number): string {
    return page === this.page ? 'page current' : 'page';
  }

  getResultInfoVars(): string[] {
    const first = (this.page - 1) * this.limit + 1;
    const last = this.page * this.limit;
    return [
      first,
      last > this.total ? this.total : last,
      this.total
    ].map(item => item as any as string);
  }

  private onPaginationReady(): void {
    this.paginationReadyLevel++;

    if (this.paginationReadyLevel > 1) {
      this.paginationReady = true;
      setTimeout(() => this.resultsInfo.emit(this.getResultInfoVars()), 50);
    }
  }

  private onLocallangReady(): void {
    this.locallangReady = true;
  }

  // item.presetFilterValue, '###business_area###', this.filterValueBusinessAreaId
  private applyToPlaceholder(model: { filterValues: any[], key: string, value: string }): this {
    const index = model.filterValues.indexOf(model.key);

    if (model.value && index >= 0) {
      model.filterValues.splice(index, 1, ...model.value.split(','));
    }

    return this;
  }

  clearAll(): void {
    location.reload();
  }

  public hasVisibleFilters(): boolean {
    return this.searchprofileConfig.config.filter(item => item.visible).length > 0;
  }
}
