<div class="vsfiles-datepicker-header">
  <button mat-icon-button class="vsfiles-datepicker-double-arrow" (click)="previousClicked('year')">
    &lsaquo;&lsaquo;
  </button>
  <button mat-icon-button (click)="previousClicked('month')">
    &lsaquo;
  </button>
  <span class="vsfiles-datepicker-header-label">{{periodLabel}}</span>
  <button mat-icon-button (click)="nextClicked('month')">
    &rsaquo;
  </button>
  <button mat-icon-button class="vsfiles-datepicker-double-arrow" (click)="nextClicked('year')">
    &rsaquo;&rsaquo;
  </button>
</div>
