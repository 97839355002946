import {AfterViewInit, Component} from '@angular/core';
import {RandomService} from '../../../libs/random.service';
import {ConversionService} from '../../../libs/conversion.service';
import {Select2Component} from '../../../components/select2/select2.component';
import {Select2OptionData} from '../../../models/select2-option-data';
import {Products} from '@verder-scientific/vs-files-client/dist/Models/products';
import {ProductService} from '../../../data-services/product.service';
import {LocallangService} from '../../../data-services/locallang.service';
import {AbstractDataService} from '../../../abstracts/abstract-data.service';

@Component({
  selector: 'app-searchprofile-products',
  templateUrl: './products.component.html',
  styleUrls: ['../input.scss']
})
export class ProductsComponent extends Select2Component implements AfterViewInit {
  constructor(
    public random: RandomService,
    public conversion: ConversionService,
    public locallangService: LocallangService,
    public dataService: ProductService,
  ) {
    super(random, conversion, locallangService, dataService as AbstractDataService);
  }

  // ngAfterViewInit(): void {
  //   super.ngAfterViewInit();
  //   this.dataService.api.setProductAkzsFilter(this.value);
  // }

  toSelect2Format(options: Products[]): Select2OptionData[] {
    return options
      .filter(item => this.filter && this.filter.length ? this.filter.includes(item.akz) : true)
      .filter(item => item.akz && item.product_name)
      .map(item => ({
        id: item.akz as any as string,
        text: (item.apply_locallang ? this.locallangService.get('product_name.' + item.akz) : item.product_name)
          .replace(/<\/?[^>]+(>|$)/g, ' ')
          .replace(/&nbsp;/g, ' ')
          .replace(/ /g, ' ')
          .trim(),
        top: item.hidden_in_navigation,
      }))
      .sort((n1, n2) => {
        if (n1.top || n2.top) {
          return 0;
        }
        if (n1.text > n2.text) {
          return 1;
        }
        if (n1.text < n2.text) {
          return -1;
        }
        return 0;
      });
  }

  toInputFormat(value: any): string[] {
    return value;
  }

  toOutputFormat(data: string[]): any {
    return data;
  }
}
