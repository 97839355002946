import {EventEmitter, Injectable, Output} from '@angular/core';
import {ElementsFileobjects} from '@verder-scientific/vs-files-client/dist/Models/elements-fileobjects';
import {Fileobjects} from '@verder-scientific/vs-files-client/dist/Models/fileobjects';
import {LanguageService} from '../data-services/language.service';
import {LocallangService} from '../data-services/locallang.service';
import {ConversionService} from '../libs/conversion.service';
import {sprintf} from 'sprintf-js';

// Declare Google Tag Manager dataLayer setup
declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Injectable({
  providedIn: 'root'
})
export class FilterSearchprofileService {
  showObjectTypeTitle = true;
  showDetails = false;
  currentFileobject = new Fileobjects();
  backLink = true;
  preselectedLanguageCode!: string;

  @Output() ViewSwitchedEvent: EventEmitter<any> = new EventEmitter();
  @Output() DownloadSelectedEvent: EventEmitter<ElementsFileobjects> = new EventEmitter();

  constructor(
    public locallangService: LocallangService,
    public languageService: LanguageService,
    public conversion: ConversionService,
  ) {
  }

  getElementLanguage(elementsFileobjects: ElementsFileobjects[]): ElementsFileobjects {
    let result: ElementsFileobjects | undefined;
    this.languageService.primaryLanguageCodes.forEach(primary_code => {
      if (!result) {
        result = elementsFileobjects.find(item => item.Language.code === primary_code && item.Element.source_id !== 'link');
      }
    });

    if (result) {
      return result;
    }

    return elementsFileobjects[0];
  }

  getElementLanguageItems(elementsFileobjects: ElementsFileobjects[], language_code: string, types: string[]): ElementsFileobjects[] {
    return elementsFileobjects.filter(item => item.Language.code === language_code && types.includes(item.Element.source_id))
      || [new ElementsFileobjects()];
  }

  getThumbnail(elementsFileobject: ElementsFileobjects): string {
    return elementsFileobject.Element ? (elementsFileobject.Element.Thumbnail.url_thumb || elementsFileobject.Element.url_thumb) : '';
  }

  getAlt(elementsFileobject: ElementsFileobjects): string {
    return elementsFileobject.Element.title;
  }

  hasElementType(elementsFileobjects: ElementsFileobjects[], type: string): boolean {
    return elementsFileobjects.filter(item => item.Element.source_id === 'link').length as any as boolean;
  }

  getChangelogSuffix(elementsFileobject: ElementsFileobjects): string {
    const is_changed = elementsFileobject.Element.flag_changelog && elementsFileobject.Element.changelog_date !== null;

    if (!is_changed) {
      return '';
    }

    const flag = this.locallangService.get(`vsfiles.download.changelog.${elementsFileobject.Element.flag_changelog}`);
    const date = this.conversion.toDate(elementsFileobject.Element.changelog_date);
    // const date = elementsFileobject.Element.changelog_date;

    return `${flag} ${date}`
      .replace(/<\/?[^>]+(>|$)/g, ' ');
  }

  trackPageview(elementsFileobject: ElementsFileobjects): void {
    const data = {
      'fileName': elementsFileobject.Element.basename,
      'urlPath': sprintf(
        '%s//%s%s',
        window.location.protocol,
        window.location.host,
        elementsFileobject.Element.url_download
      ),
      'event': 'download',
    };
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(data);
  }

  setCurrentFileobject(fileobject?: Fileobjects): void {
    const adaptionClass = 'vsfiles-visibility-adapted';
    let $container = $('app-files-searchprofile, app-files-classic').parent();
    let $nextSiblings = $($container).parents('.content').nextAll('.content');

    $($container)
      .find(`:visible:not(app-files-searchprofile, app-files-classic, script):not(.${adaptionClass})`)
      .addClass(adaptionClass);

    $nextSiblings.addClass(adaptionClass);

    const altContents = $(`.${adaptionClass}`);

    if (fileobject) {
      this.currentFileobject = fileobject;
      this.showDetails = true;
      altContents.hide();
    } else {
      this.showDetails = false;
      this.ViewSwitchedEvent.emit();
      altContents.show();
    }
  }

  getCurrentElementsFileobject(): ElementsFileobjects {
    return this.currentFileobject.ElementsFileobjects.find(e => e.language_code === this.preselectedLanguageCode)
      || this.currentFileobject.ElementsFileobjects[0];
  }

  selectDownload(elementsFileobject: ElementsFileobjects): void {
    this.DownloadSelectedEvent.emit(elementsFileobject);
  }
}
