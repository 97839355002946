import {Injectable} from '@angular/core';
import {DatePipe} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class ConversionService {
  date: DatePipe;

  constructor() {
    this.date = new DatePipe('de-DE');
  }

  toNumbers(data: string[]): number[] {
    return data ? data.map(item => +item) : [];
  }

  toStrings(data: number[]): string[] {
    return data ? data.map(item => item as any as string) : [];
  }

  toDate(date: string | null): string | null {
    return this.date.transform(date, 'mediumDate');
  }
}
