import {AfterViewInit, Component, Input} from '@angular/core';
import * as jquery from 'jquery';
import 'select2';
import {RandomService} from '../../libs/random.service';
import {ConversionService} from '../../libs/conversion.service';
import {Select2OptionData} from '../../models/select2-option-data';
import {AbstractDataService} from '../../abstracts/abstract-data.service';
import {LocallangService} from '../../data-services/locallang.service';

@Component({
  selector: 'app-select2',
  templateUrl: './select2.component.html',
  styleUrls: ['./select2.component.scss']
})
export class Select2Component implements AfterViewInit {

  @Input() value: any = [];
  @Input() filter: any = [];
  options = {
    multiple: true,
    closeOnSelect: true,
    width: '100%',
    allowClear: true,
    language: {
      noResults: () => '',
    }
  };
  id: string;
  control!: JQuery<HTMLElement>;

  constructor(
    public random: RandomService,
    public conversion: ConversionService,
    public locallangService: LocallangService,
    public dataService: AbstractDataService,
  ) {
    this.id = this.random.getId();
    this.options.language.noResults = () => locallangService.get('search.noresults');
  }

  ngAfterViewInit(): void {
    /* find control */
    this.control = jquery(`#${this.id}`);

    /* create listening event */
    this.control.select2(this.options)
      .on('select2:select', () => this.changeSelection())
      .on('select2:unselect', () => this.changeSelection());

    /* if options data changes... */
    this.dataService.LoadedEvent.subscribe((options: any) => {
      const data = this.toSelect2Format(options);

      /* IMPORTANT: empty option list first! */
      this.control.select2().empty();

      /* convert options to select2 compatible format and push into select2 control */
      this.control.select2({data});

      /* reset select2 to cached value */
      if (this.value) {
        this.control.val(this.toInputFormat(this.value)).trigger('change');
      }
    });
  }

  changeSelection(): void {
    /* cache select2 value */
    let selection
      = this.value
      = this.toOutputFormat(this.control.val() as string[]);

    if (this.filter && this.filter.length && (!this.value || (this.value && !this.value.length))) {
      selection = [...this.filter];
    }

    /* trigger service function */
    this.dataService.changeSelection(selection);
  }

  toInputFormat(value: any): string[] {
    return this.conversion.toStrings(value);
  }

  toOutputFormat(data: string[]): any {
    return this.conversion.toNumbers(data);
  }

  toSelect2Format(options: any): Select2OptionData[] {
    return options;
  }

  getClass(): string {
    return this.value.length ? 'has-selection' : 'empty-selection';
  }
}
