import {EventEmitter, Injectable, Output} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {decode} from 'html-entities';
import {sprintf} from 'sprintf-js';

@Injectable({
  providedIn: 'root'
})
export class LocallangService {
  @Output() LoadedEvent: EventEmitter<any> = new EventEmitter();
  private generalData!: any;
  private projectData!: any;
  private loaded = false;

  constructor(
    public http: HttpClient
  ) {
    this.LoadedEvent.subscribe(() => this.loaded = true);
  }

  load(projectKey: string, languageCode: string): void {
    this.http.get(`/files-client/locallang/general-${languageCode}.json`)
      .subscribe(generalData => {
        this.generalData = generalData;

        this.http.get(`/files-client/locallang/${projectKey}-${languageCode}.json`)
          .subscribe(projectData => {
            this.projectData = projectData;

            this.LoadedEvent.emit();
          });
      });
  }

  get(key: string, fallbackToPlaceholder = true, vars?: string[]): string {
    const placeholder = `[LL:${key}]`;

    if (!this.projectData) {
      return placeholder;
    }

    let locallang = (
      this.projectData[key] ? this.projectData[key].text : (
        this.generalData[key] ? this.generalData[key].text : (
          fallbackToPlaceholder ? placeholder : null
        )
      )
    );

    if (vars) {
      locallang = sprintf(locallang, ...vars);
    }

    return decode(locallang);
  }

  apply(text: string): string {
    text = text.replace(/\[LL:(.*?)]/g, (match, $1) => {
      return this.get($1);
    });
    return decode(text);
  }

  isLoaded(): boolean {
    return this.loaded;
  }
}
