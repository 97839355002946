import {EventEmitter, Injectable, Output} from '@angular/core';
import {ApiService} from './api.service';
import {Products} from '@verder-scientific/vs-files-client/dist/Models/products';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  @Output() LoadedEvent: EventEmitter<Products[]> = new EventEmitter();
  @Output() ChangedEvent: EventEmitter<Products[]> = new EventEmitter();

  data: Products[] = [];

  constructor(
    public api: ApiService,
  ) {
  }

  load(): void {
    this.api.findProducts()
      .then(data => this.LoadedEvent.emit(this.data = data as Products[]));
    // .then(data => this.LoadedEvent.emit(
    //       this.data = (data as Products[]).sort((first, second) => first.product_name < second.product_name ? 1 : -1)
    //       ));
  }

  getAll(): Products[] {
    return this.data || [];
  }

  changeSelection(data: string[]): void {
    this.api.setProductAkzsFilter(data);
    this.ChangedEvent.emit();
  }
}
