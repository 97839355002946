import {Component, HostListener, Input, OnInit} from '@angular/core';
import {ElementsFileobjects} from '@verder-scientific/vs-files-client/dist/Models/elements-fileobjects';
import {Fileobjects} from '@verder-scientific/vs-files-client/dist/Models/fileobjects';
import {LanguageService} from 'src/app/data-services/language.service';
import {FilterSearchprofileService} from 'src/app/services/filter-searchprofile.service';

@Component({
  selector: 'app-download-selector',
  templateUrl: './download-selector.component.html',
  styleUrls: ['./download-selector.component.scss']
})
export class DownloadSelectorComponent implements OnInit {
  @Input() label = 'download.link';
  @Input() fileobject!: Fileobjects;
  showSelector = false;

  @HostListener('window:click') onClick(): void {
    // this.showSelector = false;
  }

  constructor(
    public languageService: LanguageService,
    public renderService: FilterSearchprofileService,
  ) {
  }

  ngOnInit(): void {
  }

  toggleSelector(): void {
    this.showSelector = !this.showSelector;
  }

  selectDownload(elementsFileobject: ElementsFileobjects): void {
    this.renderService.selectDownload(elementsFileobject);
    this.toggleSelector();
  }
}
