import {Injectable} from '@angular/core';
import {PublicRole} from '@verder-scientific/vs-files-client/lib/Roles/public-role';
import {UserRole} from '@verder-scientific/vs-files-client/dist/Roles/user-role';
import {BackendRole} from '@verder-scientific/vs-files-client/dist/Roles/backend-role';
import {VsFilesClient} from '@verder-scientific/vs-files-client';
import {Folders} from '@verder-scientific/vs-files-client/dist/Models/folders';
import {Languages} from '@verder-scientific/vs-files-client/dist/Models/languages';
import {Meta} from '@verder-scientific/vs-files-client/dist/Models/meta';
import {Elements} from '@verder-scientific/vs-files-client/dist/Models/elements';
import {Objecttypes} from '@verder-scientific/vs-files-client/dist/Models/objecttypes';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public projectKey!: string | undefined;
  public languageCode!: string | undefined;
  public authToken!: string | undefined;
  private titleFilter = '';
  private objecttypeIds: number[] = [];
  private businessareaIds: number[] = [];
  private productgroupIds: number[] = [];
  private productAkzs: string[] = [];
  // private productIds: number[] = [];
  private changelogDate: string[] = [];
  private changelogDateRange: Date[] = [];
  private languageCodes: string[] = [];
  private page = 0;
  private limit = 10;
  private ifProductsCurrent = false;
  private ifProductsDiscontinued = false;
  private metaKeys: any = {};

  constructor() {
  }

  setProjectKey(projectKey?: string): this {
    this.projectKey = projectKey;
    return this;
  }

  setLanguageCode(languageCode?: string): this {
    this.languageCode = languageCode;
    return this;
  }

  setAuthToken(authToken?: string): this {
    this.authToken = authToken;
    return this;
  }

  setPage(page: number): this {
    this.page = page;
    return this;
  }

  setLimit(limit: number): this {
    this.limit = limit;
    return this;
  }

  public getClient(): PublicRole | UserRole | BackendRole {
    if (!this.projectKey) {
      throw new Error(`Mandatory attribute 'project-key' missing!`);
    }

    if (!this.languageCode) {
      throw new Error(`Mandatory attribute 'language-code' missing!`);
    }

    const client = new VsFilesClient(this.projectKey, this.languageCode);

    if (this.authToken) {
      return client.authenticate(this.authToken);
    }

    // Fallback to regular unauthenticated frontend access
    return new VsFilesClient(this.projectKey, this.languageCode)
      .asPublic();
  }

  setTitleFilter(value: string): void {
    this.titleFilter = value;
  }

  setObjecttypeFilter(value: number[]): void {
    this.objecttypeIds = value;
  }

  setBusinessareasFilter(value: number[]): void {
    this.businessareaIds = value;
  }

  setProductgroupsFilter(value: number[]): void {
    this.productgroupIds = value;
  }

  setProductAkzsFilter(value: string[]): void {
    this.productAkzs = value;
  }

  // setProductIdsFilter(value: number[]): void {
  //   this.productIds = value;
  // }

  setChangelogsFilter(value: string[]): void {
    this.changelogDate = value;
  }

  setChangelogsRangeFilter(value: Date[]): void {
    this.changelogDateRange = [];

    if (value.filter(i => i).length) {
      this.changelogDateRange = value;
    }
  }

  setLanguagesFilter(value: string[]): void {
    this.languageCodes = value;
  }

  setCurrentProducts(value: boolean): void {
    this.ifProductsCurrent = value;
  }

  setDiscontinuedProducts(value: boolean): void {
    this.ifProductsDiscontinued = value;
  }

  setElementMetaKeysFilter(key: string, value: string[]): void {
    if (!value.length) {
      delete this.metaKeys[key];
      return;
    }
    this.metaKeys[key] = value;
  }

  findObjectType(): Promise<any | null> {
    const client = this.getClient().setObjectTypes();

    if (this.titleFilter) {
      client.withTitle(`%${this.titleFilter}%`);
    }
    if (this.businessareaIds) {
      client.withBusinessAreas(this.businessareaIds);
    }
    if (this.productgroupIds) {
      client.withProductGroups(this.productgroupIds);
    }
    if (this.productAkzs) {
      client.withProductAkzs(this.productAkzs);
      // } else if (this.productIds) {
      //   client.withProducts(this.productIds);
    }
    if (this.ifProductsDiscontinued) {
      client.ifProductsDiscontinued(true);
    }
    if (this.ifProductsCurrent) {
      client.ifProductsDiscontinued(false);
    }
    if (this.changelogDate) {
      client.withChangelogDates(this.changelogDate);
    }
    if (this.changelogDateRange.length) {
      client.withChangelogDateBetween(this.changelogDateRange);
    }
    if (this.languageCodes) {
      client.withLanguageCodes(this.languageCodes);
    }
    if (this.metaKeys) {
      client.withElementMeta(this.metaKeys);
    }

    return client.find().all().getData();
  }

  findBusinessareas(): Promise<any | null> {
    const client = this.getClient().setBusinessAreas();

    if (this.titleFilter) {
      client.withTitle(`%${this.titleFilter}%`);
    }
    if (this.objecttypeIds) {
      client.withObjectTypes(this.objecttypeIds);
    }
    if (this.productgroupIds) {
      client.withProductGroups(this.productgroupIds);
    }
    if (this.productAkzs) {
      client.withProductAkzs(this.productAkzs);
      // } else if (this.productIds) {
      //   client.withProducts(this.productIds);
    }
    if (this.ifProductsDiscontinued) {
      client.ifProductsDiscontinued(true);
    }
    if (this.ifProductsCurrent) {
      client.ifProductsDiscontinued(false);
    }
    if (this.changelogDate) {
      client.withChangelogDates(this.changelogDate);
    }
    if (this.changelogDateRange.length) {
      client.withChangelogDateBetween(this.changelogDateRange);
    }
    if (this.languageCodes) {
      client.withLanguageCodes(this.languageCodes);
    }
    if (this.metaKeys) {
      client.withElementMeta(this.metaKeys);
    }

    return client.find().all().getData();
  }

  findProductgroups(): Promise<any | null> {
    const client = this.getClient().setProductGroups();

    if (this.titleFilter) {
      client.withTitle(`%${this.titleFilter}%`);
    }
    if (this.objecttypeIds) {
      client.withObjectTypes(this.objecttypeIds);
    }
    if (this.businessareaIds) {
      client.withBusinessAreas(this.businessareaIds);
    }
    if (this.productAkzs) {
      client.withProductAkzs(this.productAkzs);
      // } else if (this.productIds) {
      //   client.withProducts(this.productIds);
    }
    if (this.ifProductsDiscontinued) {
      client.ifProductsDiscontinued(true);
    }
    if (this.ifProductsCurrent) {
      client.ifProductsDiscontinued(false);
    }
    if (this.changelogDate) {
      client.withChangelogDates(this.changelogDate);
    }
    if (this.changelogDateRange.length) {
      client.withChangelogDateBetween(this.changelogDateRange);
    }
    if (this.languageCodes) {
      client.withLanguageCodes(this.languageCodes);
    }
    if (this.metaKeys) {
      client.withElementMeta(this.metaKeys);
    }

    return client.find().all().getData();
  }

  findProducts(): Promise<any | null> {
    const client = this.getClient().setProducts();

    if (!this.ifProductsCurrent && this.ifProductsDiscontinued) {
      client.ifProductsDiscontinued(true);
    }
    if (this.ifProductsCurrent && !this.ifProductsDiscontinued) {
      client.ifProductsDiscontinued(false);
    }

    if (this.titleFilter) {
      client.withTitle(`%${this.titleFilter}%`);
    }
    if (this.objecttypeIds) {
      client.withObjectTypes(this.objecttypeIds);
    }
    if (this.businessareaIds) {
      client.withBusinessAreas(this.businessareaIds);
    }
    if (this.productgroupIds) {
      client.withProductGroups(this.productgroupIds);
    }
    if (this.changelogDate) {
      client.withChangelogDates(this.changelogDate);
    }
    if (this.changelogDateRange.length) {
      client.withChangelogDateBetween(this.changelogDateRange);
    }
    if (this.languageCodes) {
      client.withLanguageCodes(this.languageCodes);
    }
    if (this.metaKeys) {
      client.withElementMeta(this.metaKeys);
    }

    return client.find().all().getData();
  }

  findChangelogDates(): Promise<any | null> {
    const client = this.getClient().setElements();

    if (this.titleFilter) {
      client.withTitle(`%${this.titleFilter}%`);
    }
    if (this.objecttypeIds) {
      client.withObjectTypes(this.objecttypeIds);
    }
    if (this.businessareaIds) {
      client.withBusinessAreas(this.businessareaIds);
    }
    if (this.productgroupIds) {
      client.withProductGroups(this.productgroupIds);
    }
    if (this.productAkzs) {
      client.withProductAkzs(this.productAkzs);
      // } else if (this.productIds) {
      //   client.withProducts(this.productIds);
    }
    if (this.ifProductsDiscontinued) {
      client.ifProductsDiscontinued(true);
    }
    if (this.ifProductsCurrent) {
      client.ifProductsDiscontinued(false);
    }
    if (this.languageCodes) {
      client.withLanguageCodes(this.languageCodes);
    }

    return client
      .find()
      .select(new Elements(), ['changelog_date'])
      .group(['changelog_date'])
      .order(new Elements(), ['changelog_date'])
      .all()
      .getData();
  }

  findLanguages(): Promise<any | null> {
    const client = this.getClient().setLanguages();

    if (this.titleFilter) {
      client.withTitle(`%${this.titleFilter}%`);
    }
    if (this.objecttypeIds) {
      client.withObjectTypes(this.objecttypeIds);
    }
    if (this.businessareaIds) {
      client.withBusinessAreas(this.businessareaIds);
    }
    if (this.productgroupIds) {
      client.withProductGroups(this.productgroupIds);
    }
    if (this.productAkzs) {
      client.withProductAkzs(this.productAkzs);
      // } else if (this.productIds) {
      //   client.withProducts(this.productIds);
    }
    if (this.ifProductsDiscontinued) {
      client.ifProductsDiscontinued(true);
    }
    if (this.ifProductsCurrent) {
      client.ifProductsDiscontinued(false);
    }
    if (this.changelogDate) {
      client.withChangelogDates(this.changelogDate);
    }
    if (this.changelogDateRange.length) {
      client.withChangelogDateBetween(this.changelogDateRange);
    }
    if (this.languageCodes) {
      client.withLanguageCodes(this.languageCodes);
    }
    if (this.metaKeys) {
      client.withElementMeta(this.metaKeys);
    }

    return client
      .find()
      .group(['sl_language_uid'])
      .all()
      .getData();
  }

  findMeta(): Promise<any | null> {
    const client = this.getClient().setMeta();

    if (this.titleFilter) {
      client.withTitle(`%${this.titleFilter}%`);
    }
    if (this.objecttypeIds) {
      client.withObjectTypes(this.objecttypeIds);
    }
    if (this.businessareaIds) {
      client.withBusinessAreas(this.businessareaIds);
    }
    if (this.productgroupIds) {
      client.withProductGroups(this.productgroupIds);
    }
    if (this.productAkzs) {
      client.withProductAkzs(this.productAkzs);
      // } else if (this.productIds) {
      //   client.withProducts(this.productIds);
    }
    if (this.ifProductsDiscontinued) {
      client.ifProductsDiscontinued(true);
    }
    if (this.ifProductsCurrent) {
      client.ifProductsDiscontinued(false);
    }
    if (this.changelogDate) {
      client.withChangelogDates(this.changelogDate);
    }
    if (this.changelogDateRange.length) {
      client.withChangelogDateBetween(this.changelogDateRange);
    }
    if (this.languageCodes) {
      client.withLanguageCodes(this.languageCodes);
    }
    if (this.metaKeys) {
      client.withElementMeta(this.metaKeys);
    }

    return client.find().all().getData();
  }

  findElementsMeta(): Promise<any | null> {
    const client = this.getClient().setElementsMeta();

    if (this.titleFilter) {
      client.withTitle(`%${this.titleFilter}%`);
    }
    if (this.objecttypeIds) {
      client.withObjectTypes(this.objecttypeIds);
    }
    if (this.businessareaIds) {
      client.withBusinessAreas(this.businessareaIds);
    }
    if (this.productgroupIds) {
      client.withProductGroups(this.productgroupIds);
    }
    if (this.productAkzs) {
      client.withProductAkzs(this.productAkzs);
      // } else if (this.productIds) {
      //   client.withProducts(this.productIds);
    }
    if (this.ifProductsDiscontinued) {
      client.ifProductsDiscontinued(true);
    }
    if (this.ifProductsCurrent) {
      client.ifProductsDiscontinued(false);
    }
    if (this.changelogDate) {
      client.withChangelogDates(this.changelogDate);
    }
    if (this.changelogDateRange.length) {
      client.withChangelogDateBetween(this.changelogDateRange);
    }
    if (this.languageCodes) {
      client.withLanguageCodes(this.languageCodes);
    }
    if (this.metaKeys) {
      client.withElementMeta(this.metaKeys);
    }

    return client.find().all().getData();
  }

  find(): Promise<any | null> {
    const client = this.getClient().setFileobjects();
    if (this.titleFilter) {
      client.withTitle(`%${this.titleFilter}%`);
    }
    if (this.objecttypeIds) {
      client.withObjectTypes(this.objecttypeIds);
    }
    if (this.businessareaIds) {
      client.withBusinessAreas(this.businessareaIds);
    }
    if (this.productgroupIds) {
      client.withProductGroups(this.productgroupIds);
    }
    if (this.productAkzs) {
      client.withProductAkzs(this.productAkzs);
      // } else if (this.productIds) {
      //   client.withProducts(this.productIds);
    }
    if (this.ifProductsDiscontinued) {
      client.ifProductsDiscontinued(true);
    }
    if (this.ifProductsCurrent) {
      client.ifProductsDiscontinued(false);
    }
    if (this.changelogDate) {
      client.withChangelogDates(this.changelogDate);
    }
    if (this.changelogDateRange.length) {
      client.withChangelogDateBetween(this.changelogDateRange);
    }
    if (this.languageCodes) {
      client.withLanguageCodes(this.languageCodes);
    }
    if (this.metaKeys) {
      client.withElementMeta(this.metaKeys);
    }

    return client.find()
      .select(new Objecttypes())
      .select(new Elements())
      .select(new Folders())
      .select(new Languages())
      .select(new Meta())
      // .select(new Industries())
      // .select(new Businessareas())
      // .select(new Productgroups())
      // .select(new Products())
      .limit(this.limit)
      .page(this.page)
      .order(new Objecttypes(), ['title'])
      .all()
      .execute();
  }
}
