import {EventEmitter, Injectable, Output} from '@angular/core';
import {ApiService} from './api.service';
import {Objecttypes} from '@verder-scientific/vs-files-client/dist/Models/objecttypes';

@Injectable({
  providedIn: 'root'
})
export class ObjecttypeService {
  @Output() LoadedEvent: EventEmitter<Objecttypes[]> = new EventEmitter();
  @Output() ChangedEvent: EventEmitter<Objecttypes[]> = new EventEmitter();

  data: Objecttypes[] = [];

  constructor(
    public api: ApiService,
  ) {
  }

  load(): void {
    this.api.findObjectType()
      .then(data => this.LoadedEvent.emit(this.data = data as Objecttypes[]));
  }

  getAll(): Objecttypes[] {
    return this.data || [];
  }

  changeSelection(data: number[]): void {
    this.api.setObjecttypeFilter(data);
    this.ChangedEvent.emit();
  }
}
