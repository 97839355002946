import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {debounceTime} from 'rxjs/operators';
import {LocallangService} from 'src/app/data-services/locallang.service';
import {ElementService} from '../../../data-services/element.service';

@Component({
  selector: 'app-searchprofile-title',
  templateUrl: './title.component.html',
  styleUrls: [
    './title.component.scss',
    '../input.scss',
  ]
})
export class TitleComponent implements OnInit {

  @Input() value: any;
  public InputControl = new UntypedFormControl();

  constructor(
    public locallangService: LocallangService,
    public dataService: ElementService,
  ) {
  }

  ngOnInit(): void {
    this.dataService.api.setTitleFilter(this.value);
    this.InputControl.setValue(this.value);

    this.InputControl.valueChanges
      .pipe(debounceTime(400))
      .subscribe(data => this.dataService.changeTitleFilter(data));
  }
}
