<ng-container *ngIf="isDetailView || isPlayingVideo(elementsFileobject)">
  <div [id]="'#fancybox' + elementsFileobject.id" [class]="!isDetailView ? 'videobox__backdrop' : ''"
       (click)="!isDetailView && stopVideo()">
    <div class="videobox__buttons">
      <button (click)="stopVideo()" class="videobox__button videobox__button-close"></button>
      <button (click)="$event.stopPropagation();requestFullscreen()"
              *ngIf="!isPlayingRemoteVideo()"
              class="videobox__button videobox__button-requestFullscreen"></button>
    </div>

    <div class="videobox__stage" (click)="$event.stopPropagation();">

      <h1 class="center first">
              <span
                [innerHTML]="renderService.getElementLanguage(fileobject.ElementsFileobjects).Element.title | safeHtml"
                *ngIf="!isDetailView"></span>
        <span
          [innerHTML]="renderService.getCurrentElementsFileobject().Element.title | safeHtml"
          *ngIf="isDetailView"></span>
      </h1>

      <div class="videobox__video">
        <video #video *ngIf="!isPlayingRemoteVideo()" controls autoplay preload="auto" id="videobox__fullscreen">
          <source [src]="elementsFileobject.Element.url_download" [type]="elementsFileobject.Element.filetype"/>
        </video>
        <div *ngIf="isPlayingRemoteVideo()" class="videobox__video-iframe">
          <iframe [src]="getRemoteVideoLink(elementsFileobject)"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen></iframe>
        </div>
      </div>

      <div class="videobox__footer">
        <div *ngIf="isDetailView && fileobject.ElementsFileobjects.length > 1">
          <app-download-selector label="downloads_dropdown_play" [fileobject]="fileobject">
          </app-download-selector>
        </div>

        <div *ngIf="getRemoteVideoLink(elementsFileobject)" (click)="toggleRemoteVideo()">
          <input type="checkbox" [ngModel]="playRemoteVideo"/>
          <app-locallang [key]="'download.video_allow_youtube'"></app-locallang>
        </div>
      </div>
    </div>
  </div>
</ng-container>
